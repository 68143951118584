import rpcService from "@/services/rpcService";

// STATE
const state = {
  orderList: [],
  orderViewType: "me"
};

// ACTIONS
const actions = {
  fetchOrderList: context => {
    return rpcService
      .rpc("getOrderList", {})
      .then(response => {
        context.commit("SET_ORDER_LIST", response.data.orderList);
      })
      .catch(error => {
        console.log(error.message);
      });
  },
  clearStore: context => {
    context.commit("SET_ORDER_LIST", []);
  },
  setOrderViewType: (context, orderViewType) => {
    context.commit("SET_ORDER_VIEW_TYPE", orderViewType);
  }
};

// MUTATIONS
const mutations = {
  SET_ORDER_LIST: (state, payload) => {
    state.orderList = payload;
  },
  SET_ORDER_VIEW_TYPE: (state, payload) => {
    state.orderViewType = payload;
  }
};

// GETTERS
const getters = {
  orderView: state => {
    if (state.orderViewType === 1) {
      return state.orderList.filter(orderList => orderList.isMe === true);
    }
    return state.orderList;
  },
  orderCount: (state, getters) => {
    return getters.orderView.length;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
