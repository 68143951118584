import Vue from "vue";
import Vuex from "vuex";
import shoppingCart from "@/store/modules/shoppingCart";
import productCatalog from "@/store/modules/productCatalog";
import orders from "@/store/modules/orders";
import account from "@/store/modules/account";
import session from "@/store/modules/session";

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    productCatalog,
    shoppingCart,
    orders,
    account,
    session,
  },
});
export default store;
