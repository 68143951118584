import axios from "axios";
import store from "@/store";

const baseURL = "https://tn1.caas.be/darlii/web";

const apiClient = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export default {
  rpc(name, params) {
    // add webSessionId to params
    const x = store.getters["session/sessionActive"];
    if (x) {
      const y = store.getters["session/webSessionId"];
      params.webSessionId = y;
    }
    // build requestBody
    let oBody = {
      request: {
        environment: process.env.NODE_ENV,
        rpcName: name,
        params: params
      }
    };
    return apiClient.post("/rpc", oBody);
  }
};
