import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import AutoLogin from "@/views/AutoLogin";
import Login from "@/views/Login";

Vue.use(VueRouter);

function lazyLoad(view) {
  return () => {
    return import(`@/views/${view}.vue`);
  };
}

const routes = [
  {
    path: "/",
    name: "AutoLogin",
    component: AutoLogin,
    meta: { requiresAuth: false }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: "/home",
    name: "Home",
    component: lazyLoad("Home"),
    meta: { requiresAuth: true }
  },
  {
    path: "/ecatalog",
    name: "ECatalog",
    components: {
      default: lazyLoad("ECatalog"),
      toolbar: lazyLoad("ECatalogTB")
    },
    meta: { requiresAuth: true }
  },
  {
    path: "/ecart",
    name: "ECart",
    component: lazyLoad("ECart"),
    meta: { requiresAuth: true }
  },
  {
    path: "/eaccount",
    name: "EAccount",
    component: lazyLoad("EAccount"),
    meta: { requiresAuth: true }
  },
  {
    path: "/eorders",
    name: "EOrders",
    component: lazyLoad("EOrders"),
    meta: { requiresAuth: true }
  }
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(record => record.meta.requiresAuth) &&
    !store.getters["session/sessionActive"]
  ) {
    next({ name: "AutoLogin" });
  }
  next();
});

export default router;
