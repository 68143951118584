import rpcService from "@/services/rpcService";

// STATE
const state = {
  webSessionId: "",
};
// ACTIONS
const actions = {
  login: (context, credentials) => {
    return rpcService
      .rpc("webLogin", credentials)
      .then((response) => {
        if (response.data.error) {
          throw "We kunnen je gebruiker/wachtwoord niet terugvinden in onze lijst.";
        } else {
          localStorage.setItem("webSessionId", response.data.webSessionId);
          context.commit("SET_WEBSESSION_ID", response.data);
          context.dispatch("account/setUserName", response.data, {
            root: true,
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  logout: (context) => {
    return rpcService
      .rpc("webLogout", { webSessionId: state.webSessionId })
      .catch((error) => {
        console.log("Error logging out: ", error);
      })
      .finally(() => {
        // clear local storage
        localStorage.setItem("webSessionId", "");
        // clear store
        context.commit("SET_WEBSESSION_ID", "");
        location.reload();
      });
  },
  validateWebSession: (context, params) => {
    return rpcService
      .rpc("validateWebSession", params)
      .then((response) => {
        if (response.data.isValid) {
          context.commit("SET_WEBSESSION_ID", response.data);
          context.dispatch("account/setUserName", response.data, {
            root: true,
          });
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  },
};
// MUTATIONS
const mutations = {
  SET_WEBSESSION_ID: (state, payload) => {
    state.webSessionId = payload.webSessionId;
  },
};
// GETTERS
const getters = {
  sessionActive: (state) => {
    if (state && state.webSessionId && state.webSessionId !== "") {
      return true;
    }
    return false;
  },
  webSessionId: (state) => {
    return state.webSessionId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
