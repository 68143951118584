import rpcService from "@/services/rpcService";

// STATE
const state = {
  catalogList: [],
  categoryList: [],
  itemList: [],
  ui: {
    gridView: true,
    loading: false,
    searchText: "",
    currentCatalog: {},
    currentCategory: {
      id: 0,
      name: "Kies een categorie"
    }
  }
};

// ACTIONS
const actions = {
  fetchCatalog: context => {
    let params = {};
    if (state.catalogList[0]) {
      return;
    }
    // catalog
    return rpcService
      .rpc("getCatalogList", params)
      .then(response => {
        context.commit("SET_CATALOG_LIST", response.data.catalogList);
        context.commit("SET_CURRENT_CATALOG", response.data.catalogList[0]);
        //fetch categories of first catalog
        params = { catalogId: response.data.catalogList[0].catalogId };
        rpcService
          .rpc("getCategoryList", params)
          .then(response => {
            context.commit("SET_CATEGORY_LIST", response.data.categoryList);
          })
          .catch(error => {
            console.log(error.message);
          });
      })
      .catch(error => {
        console.log(error.message);
      });
  },
  fetchCatalogItemList: context => {
    // in store?
    let fetched = state.itemList.some(
      catalogItem =>
        catalogItem.catalogId === state.ui.currentCatalog.catalogId &&
        catalogItem.categoryId === state.ui.currentCategory.id
    );
    if (fetched) return;
    // get itemList from API
    let params = {
      catalogId: state.ui.currentCatalog.catalogId,
      categoryId: state.ui.currentCategory.id
    };
    return rpcService.rpc("getCatalogItemList", params).then(response => {
      context.commit("PUSH_ITEM_LIST", response.data.catalogItemList);
    });
  },
  removeCartItem: (context, pCartItem) => {
    // remove ref to cartItem from catalogItemList
    let x = state.itemList;
    let y = x.findIndex(
      catalogItem => catalogItem.cartItemId === pCartItem.cartItemId
    );
    if (y >= 0) {
      delete x[y].cartItemId;
      delete x[y].qtyInCart;
    }
  },
  removeAllCartItems: () => {
    // remove refs to all cartItems from catalogItemList
    let x = state.itemList;
    x.forEach(function(catalogItem) {
      if (catalogItem.cartItemId) {
        delete catalogItem.cartItemId;
      }
      if (catalogItem.qtyInCart) {
        delete catalogItem.qtyInCart;
      }
    });
  },
  setCurrentCategory: (context, pCategory) => {
    context.commit("SET_CURRENT_CATEGORY", pCategory);
  },
  setIsLoading: (context, pLoading) => {
    context.commit("SET_CATALOG_UI", {
      loading: pLoading
    });
  },
  setSearchText: (context, pSearchText) => {
    context.commit("SET_SEARCH_TEXT", pSearchText);
  },
  toggleGridView: context => {
    context.commit("SET_CATALOG_UI", {
      gridView: !state.ui.gridView
    });
  }
};

// MUTATIONS
const mutations = {
  SET_CATALOG_LIST: (state, payload) => {
    state.catalogList = payload;
  },
  SET_CATEGORY_LIST: (state, payload) => {
    state.categoryList = payload;
  },
  SET_CURRENT_CATALOG: (state, payload) => {
    state.ui.currentCatalog = payload;
  },
  SET_CURRENT_CATEGORY: (state, payload) => {
    state.ui.currentCategory = payload;
  },
  PUSH_ITEM_LIST: (state, payload) => {
    state.itemList.push(...payload);
  },
  SET_CATALOG_UI: (state, payload) => {
    if (payload.gridView == true) {
      state.ui.gridView = true;
    }
    if (payload.gridView == false) {
      state.ui.gridView = false;
    }
    if (payload.loading == true) {
      state.ui.loading = true;
    }
    if (payload.loading == false) {
      state.ui.loading = false;
    }
  },
  SET_SEARCH_TEXT: (state, payload) => {
    state.ui.searchText = payload;
  }
};

// GETTERS
const getters = {
  catalogItemList: (state, getters, rootState, rootGetters) => {
    /* 
			1. filter catalogItems by catalog, category 
			2. filter catalogItems by searchText
			3. add reference if catalogItem is in shoppingCart     
		*/
    let x = state.itemList.filter(
      catalogItem =>
        catalogItem.catalogId === state.ui.currentCatalog.catalogId &&
        catalogItem.categoryId === state.ui.currentCategory.id
    );
    let y = state.ui.searchText;
    if (y) {
      x = x.filter(item => {
        let pattern = new RegExp(y, "i");
        return (
          item.itemName.search(pattern) > -1 ||
          item.productCode.search(pattern) > -1
        );
      });
    }
    let z = rootGetters["shoppingCart/cartItems"];
    if (z) {
      x.forEach(function(catalogItem) {
        let idx = z.findIndex(
          cartItem => cartItem.productCode === catalogItem.productCode
        );
        if (idx >= 0) {
          catalogItem.cartItemId = z[idx].cartItemId;
          catalogItem.qtyInCart = z[idx].quantity;
        }
      });
    }
    // sort
    x.sort(function(a, b) {
      if (a.productCode < b.productCode) {
        return -1;
      }
      if (a.productCode > b.productCode) {
        return 1;
      }
      return 0;
    });
    return x;
  },
  catalogItemListCount: (state, getters) => {
    return getters.catalogItemList.length;
  },
  catalogIsLoading: state => {
    return state.ui.loading;
  },
  catalogGridView: state => {
    return state.ui.gridView;
  },
  categoryList: state => {
    return state.categoryList;
  },
  currentCategoryName: state => {
    return state.ui.currentCategory.name;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
