import rpcService from "@/services/rpcService";

// state
const state = {
  cart: [],
  cartItems: []
};
// getters
const getters = {
  cartItems: state => {
    // sort by createdOn
    if (state.cartItems) {
      state.cartItems.sort(function(a, b) {
        return new Date(a.createdOn) - new Date(b.createdOn);
      });
    }
    return state.cartItems;
  },
  cartItemCount: state => {
    if (state.cartItems) {
      return state.cartItems.length;
    } else {
      return 0;
    }
  }
};
// actions
const actions = {
  convertShoppingCart: (context, pRemark) => {
    const params = {
      remark: pRemark
    };
    return rpcService.rpc("convertShoppingCart", params).then(response => {
      if (response.data.error) {
        throw response.data.error;
      }
      context.commit("SET_SHOPPING_CART", response.data.shoppingCart);
      // remove all refs to cartItem from catalogItemList
      context.dispatch("productCatalog/removeAllCartItems", null, {
        root: true
      });
    });
  },
  deleteCartItem: (context, pCartItem) => {
    let params = {
      cartItemId: pCartItem.cartItemId
    };
    return rpcService.rpc("deleteShoppingCartItem", params).then(response => {
      context.commit("SET_SHOPPING_CART", response.data.shoppingCart);
      // remove the deleted cartItem from the productCatalog
      context.dispatch("productCatalog/removeCartItem", pCartItem, {
        root: true
      });
    });
  },
  upsertCartItem: (context, cartItemUpd) => {
    return rpcService
      .rpc("upsertShoppingCartItem", cartItemUpd)
      .then(response => {
        context.commit("SET_SHOPPING_CART", response.data.shoppingCart);
      });
  },
  fetchShoppingCart: context => {
    return rpcService
      .rpc("getShoppingCart", {})
      .then(response => {
        context.commit("SET_SHOPPING_CART", response.data.shoppingCart);
      })
      .catch(error => {
        console.log(error);
      });
  }
};
// mutations
const mutations = {
  SET_SHOPPING_CART: (state, payload) => {
    if (!payload) return;
    state.cart = payload.cart[0];
    state.cartItems = payload.cart[0].cartItems;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
