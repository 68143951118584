<template>
	<v-container class="fill-height">
		<v-row style="height: 75%">
			<v-progress-circular
				class="mx-auto"
				color="primary"
				size="96"
				indeterminate
			/>
		</v-row>
	</v-container>
</template>
<script>
	export default {
		name: "AutoLogin",
		mounted() {
			let that = this;
			setTimeout(function () {
				that.autoLogin();
			}, 1500);
		},
		methods: {
			autoLogin() {
				let params = {
					webSessionId: localStorage.getItem("webSessionId"),
				};
				this.$store
					.dispatch("session/validateWebSession", params)
					.then((success) => {
						if (success) {
							this.$router.push({ name: "Home" });
						} else {
							this.$router.push({ name: "Login" });
						}
					});
			},
		},
	};
</script>
