import rpcService from "@/services/rpcService";

// STATE
const state = {
  user: {
    lastName: "",
    firstName: "",
  },
  orderHistory: [],
};

// ACTIONS
const actions = {
  setUserName: (context, pUserName) => {
    context.commit("SET_USER_NAME", pUserName);
  },
  fetchOrderHistory: (context) => {
    return rpcService.rpc("getOrderHistory", {}).then((response) => {
      context.commit("SET_ORDER_HISTORY", response.data.orderHistory);
    });
  },
};

// MUTATIONS
const mutations = {
  SET_USER_NAME: (state, payload) => {
    state.user.lastName = payload.userLastName;
    state.user.firstName = payload.userFirstName;
  },
  SET_ORDER_HISTORY: (state, payload) => {
    state.orderHistory = payload;
  },
};

// GETTERS
const getters = {
  firstName: (state) => {
    return state.user.firstName;
  },
  lastName: (state) => {
    return state.user.lastName;
  },
  orderHistory: (state) => {
    if (state.orderHistory) {
      state.orderHistory.sort(function(a, b) {
        return b.orderQty - a.orderQty;
      });
      return state.orderHistory;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
