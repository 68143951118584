<style scoped>
.background {
  background-image: url("../assets/login.jpg");
  background-size: 100%;
  background-position: left bottom;
}
</style>

<template>
  <v-container v-if="!sessionActive" fluid class="fill-height background">
    <v-card
      class="elevation-12 mx-auto mb-auto"
      :width="this.$vuetify.breakpoint.smAndUp ? '400px' : '90%'"
    >
      <v-card-title>Darling Ingrediënts</v-card-title>
      <v-card-subtitle>Toegang tot het analisten portaal</v-card-subtitle>
      <v-divider />
      <v-container>
        <v-row
          no-gutters
          :style="{
            height: this.$vuetify.breakpoint.smAndUp ? '350px' : '225px'
          }"
          align-content="center"
          class="px-2 px-md-4 px-lg-8"
        >
          <v-col cols="12">
            <v-form @keyup.native.enter="submitLogin">
              <v-text-field
                v-model="userName"
                type="text"
                placeholder="Gebruiker"
                prepend-icon="mdi-account-circle"
              />
              <v-text-field
                v-model="userPwd"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Wachtwoord"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              />
            </v-form>
          </v-col>
          <v-col v-if="error" cols="12">
            <v-card-text class="red--text mx-auto">
              {{ errMsg }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          :loading="loading"
          text
          type="submit"
          color="primary"
          @click.stop="submitLogin"
        >
          Aanmelden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Login",
  data: () => ({
    userName: "",
    userPwd: "",
    showPassword: false,
    error: false,
    errMsg: "",
    loading: false
  }),
  computed: {
    ...mapGetters({
      sessionActive: "session/sessionActive"
    })
  },
  methods: {
    submitLogin() {
      const that = this;
      this.loading = true;
      window.setTimeout(function() {
        that.login();
      }, 2000);
    },
    login() {
      this.loading = true;
      this.$store
        .dispatch("session/login", {
          userName: this.userName,
          userPwd: this.userPwd
        })
        .then(() => {
          this.$router.push({ name: "Home" });
        })
        .catch(err => {
          this.error = true;
          this.errMsg = err;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
