<template>
  <v-app id="app">
    <!-- NAV-DRAWER  -->
    <v-navigation-drawer
      v-if="sessionActive && drawer"
      v-model="drawer"
      app
      right
    >
      <v-list>
        <v-list-item
          v-for="(menuItem, index) in menuItems"
          :key="index"
          router
          :to="{ name: menuItem.routeName }"
        >
          <v-list-item-action>
            <v-icon>{{ menuItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ menuItem.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item link :to="{ name: 'EAccount' }">
          <v-list-item-action>
            <v-icon>mdi-account-outline</v-icon>
          </v-list-item-action>
          Account
        </v-list-item>
        <v-list-item><v-divider /></v-list-item>
        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          Afmelden
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- APP BAR -->
    <v-app-bar
      v-show="sessionActive"
      app
      elevation="1"
      dense
      rounded
      color="white"
      :height="'100%'"
      hide-on-scroll
    >
      <v-container fluid class="pa-0">
        <v-row no-gutters align="center">
          <v-col md="2">
            <!-- logo / home -->
            <v-toolbar-items>
              <v-btn
                text
                color="white"
                class="white pl-0"
                :to="{ name: 'Home' }"
              >
                <v-img
                  position="left center"
                  src="@/assets/darlii.png"
                  height="52px"
                  contain
                />
              </v-btn>
            </v-toolbar-items>
          </v-col>
          <!-- menu items -->
          <v-col md="8" class="hidden-sm-and-down">
            <v-toolbar-items>
              <v-tabs centered optional show-arrows hide-slider icons-and-text>
                <v-tab
                  v-for="(menuItem, index) in menuItems"
                  :key="index"
                  class="px-8"
                  link
                  router
                  :to="{ name: menuItem.routeName }"
                  @change="onTabActive(menuItem.tbName)"
                >
                  {{ menuItem.text }}
                  <v-icon>
                    {{ menuItem.icon }}
                  </v-icon>
                </v-tab>
              </v-tabs>
            </v-toolbar-items>
          </v-col>
          <!-- account/logout -->
          <v-col md="2" class="hidden-sm-and-down">
            <v-toolbar-items>
              <v-spacer />
              <v-menu left bottom offset-y min-width="200px">
                <template v-slot:activator="{ on }">
                  <v-btn large icon plain v-on="on">
                    <v-icon>mdi-account</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link :to="{ name: 'EAccount' }">
                    Account
                  </v-list-item>
                  <v-divider />
                  <v-list-item @click="logout">
                    Afmelden
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar-items>
          </v-col>
          <!-- nav-drawer-icon -->
          <v-col cols="1" class="hidden-md-and-up">
            <v-toolbar-items>
              <v-spacer />
              <v-app-bar-nav-icon @click="drawer = !drawer" />
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-container>
      <!-- EXTENSION FOR ROUTER-BASED TOOLBAR -->
      <template v-if="showTB" v-slot:extension>
        <router-view name="toolbar"></router-view>
      </template>
    </v-app-bar>
    <!-- MAIN -->
    <v-main class="green lighten-3">
      <transition name="fade" mode="out-in">
        <!-- <keep-alive> -->
        <router-view />
        <!-- </keep-alive> -->
      </transition>
    </v-main>
    <!-- APP FOOTER -->
    <v-footer v-if="this.$vuetify.breakpoint.smAndUp" app class="transparent">
      <span class="text-caption"> Powered by Continuans</span>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  data: () => ({
    drawer: null,
    showTB: false,
    menuItems: [
      {
        id: 0,
        text: "Dashboard",
        routeName: "Home",
        icon: "mdi-view-dashboard-outline"
      },
      {
        id: 1,
        text: "Producten",
        routeName: "ECatalog",
        tbName: "ECatalogTB",
        icon: "mdi-book-open-outline"
      },
      {
        id: 2,
        text: "Mandje",
        routeName: "ECart",
        icon: "mdi-basket-outline"
      },
      {
        id: 3,
        text: "Bestellingen",
        routeName: "EOrders",
        icon: "mdi-truck-outline"
      }
    ]
  }),
  computed: {
    ...mapGetters({
      sessionActive: "session/sessionActive"
    })
  },
  methods: {
    logout() {
      this.$store.dispatch("session/logout").finally(() => {
        this.drawer = null;
      });
    },
    onTabActive(tbName) {
      if (tbName) {
        this.showTB = true;
      } else {
        this.showTB = false;
      }
    }
  }
};
</script>

<style>
/*** TRANSITIONS ***/
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
